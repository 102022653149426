export const GetUser = `query MyQuery {
    getUser {
        UserID
        UserName
        Email
        CognitoUserID
        Role
    }
}`;

export const GetRemainingPoint = `query MyQuery {
    getRemainingPoint {
        Point
    }
}`;

export const GetRemainingCredit = `query MyQuery {
    getRemainingCredit {
        Point
    }
}`;

export const GetAccountSetting = `query MyQuery {
    getAccountSetting {
        Avatar {
            bucket
            key
            region
        }
        Email
        UserName
        DisplayName
        CaptivatedBy
        NeedToLive
        GratefulFor
        Private
        Pronouns {
            Gender
            Name
            Key
        }
        SocialLink {
            Facebook
            Twitter
            Instagram
        }
        CreatedAt
    }
}`;

export const SettingAccount = `mutation MyMutation($Avatar: S3Object, $CaptivatedBy: String, $DisplayName: String, $GratefulFor: String, $NeedToLive: String, $Private: Int, $Pronouns: [PronounObject], $SocialLink: SocialLinkObject) {
    settingAccount(input: {Avatar: $Avatar, CaptivatedBy: $CaptivatedBy, DisplayName: $DisplayName, GratefulFor: $GratefulFor, NeedToLive: $NeedToLive, Private: $Private, Pronouns: $Pronouns, SocialLink: $SocialLink}) {
        Message
        Status
    }
}`;

export const DeleteAccountMutation = `mutation MyMutation($CognitoUserID: String, $UserName: String){
    deleteAccount(input: {CognitoUserID: $CognitoUserID, UserName: $UserName}) {
        Message
        Status
    }
}`

export const CreateSocialPost = `mutation MyMutation($Comment: String!, $File: S3Object, $Title: String!) {
    createSocialPost(input: {Comment: $Comment, File: $File, Title: $Title}) {
        Status
        Message
    }
}`

export const GetSocialPost = `query MyQuery($CognitoUserID: String) {
    listSocialPosts(CognitoUserID: $CognitoUserID) {
        SocialPostID
        Title
        Comment
        CreatedAt
        UpdatedAt
        Status
        File {
            bucket
            key
            region
        }
    }
}`

export const ListMyVideos = `query MyQuery($CognitoUserID: String) {
    listMyVideos(CognitoUserID: $CognitoUserID) {
        UserName
        VideoID
        VideoTitle
        VideoDescription
        VideoFilm
        VideoTags
        VideoDuration
        Status
        CreatedAt
        UpdatedAt
        Stills {
            bucket
            key
            region
        }
    }
}`

export const ListEnyoyedVideos = `query MyQueryMyQuery($CognitoUserID: String) {
    listEnjoyedVideos(CognitoUserID: $CognitoUserID) {
        UserName
        VideoID
        VideoTitle
        VideoDescription
        VideoFilm
        VideoTags
        VideoDuration
        Status
        CreatedAt
        UpdatedAt
        Stills {
            bucket
            key
            region
        }
    }
}`

export const GetAccounts = `query MyQuery($NumberItem: Int!, $Page: Int!, $Search: String) {
    listAccount(Paging: {NumberItem: $NumberItem, Page: $Page}, Search: $Search) {
        UserName
        DisplayName
        Avatar {
            bucket
            key
            region
        }
        CognitoUserID
        totalCount
    }
}`

export const getAccount = `query MyQuery($UserName: String, $CognitoUserID: String) {
    getAccount(UserName: $UserName, CognitoUserID: $CognitoUserID) {
        CognitoUserID
        Email
        UserName
        DisplayName
        CaptivatedBy
        NeedToLive
        GratefulFor
        CreatedAt
        Avatar {
            bucket
            key
            region
        }
        Pronouns {
            Gender
            Key
            Name
        }
        SocialLink {
            Facebook
            Twitter
            Instagram
        }
    }
}`

export const GetSummary = `query MyQuery {
    getSummary {
        LifeTime {
            VideoID
            VideoTitle
            Like
            Earn
            Stills {
                bucket
                region
                key
                order
            }
            Rent
        }
        Monthly {
            VideoID
            VideoTitle
            Rent
            Like
            Earn
            Stills {
                bucket
                region
                key
                order
            }
        }
        }
    }
`

export const CheckUserEmail = `query MyQuery($Email: String!) {
    checkUserEmail(Email: $Email) {
        UserName
        Email
        CognitoUserID
        Deleted
    }

    checkGifteeSubscription(Email: $Email) {
        Package
        StartDate
        Status
        Type
        UserSubscriptionID
    }
}`

export const GetDonation = `query MyQuery {
    getDonation {
        UserSubscriptionID
        Type
        Package
        Status
        StartDate
    }
}`;

export const GetSubAdmin = `query MyQuery($UserID: Int) {
    getSubAdmin(UserID: $UserID) {
        Point {
            SubPoint
            BundlePoint
        }
        SubPlan {
            Name
            Package
            Status
            StartDate
            CreatedAt
            UserSubscriptionID
            ParentPackage
        }
    }
}`;

export const GetEarningAdmin = `query MyQuery($UserID: Int) {
    getEarningAdmin(UserID: $UserID) {
        Earning {
            RentNumber
            MonthlyEarning
            LifetimeEarning
        }
        VideoSubmit {
            Total
            First
            Last
        }
    }
}`;

export const ListUserAdmin = `query MyQuery($Page: Int, $Limit: Int, $Search: String, $Filter: String) {
    listUserAdmin(input: {Page: $Page, Limit: $Limit, Search: $Search, Filter: $Filter}) {
        Count {
            Total
            Admin
            Star
            Member
            NoRole
            Inactive
        }
        Items {
            UserID
            Email
            UserName
            DisplayName
            CaptivatedBy
            NeedToLive
            GratefulFor
            Private
            CreatedAt
            Deleted
            Role
            Post
            Status
            VerifyLink
            LastLogin
        }
    }
}`

export const DeleteUser = `mutation MyMutation($UserID: Int!) {
    deleteUserAdmin(UserID: $UserID) {
        Message
        Status
    }
}`

export const GetUserAdmin = `query MyQuery($UserID: Int!) {
  getUserAdmin(UserID: $UserID) {
    UserID
    Email
    UserName
    DisplayName
    CaptivatedBy
    NeedToLive
    GratefulFor
    Private
    CreatedAt
    Deleted
    Role
    Post
    Status
    VerifyLink
    Avatar {
        bucket
        region
        key
        order
    }
    Pronouns {
        Gender
        Name
        Key
    }
    SocialLink {
        Facebook
        Twitter
        Instagram
    }
    LastLogin
  }
}`

export const CreateUserAdmin = `mutation MyMutation($Email: String!, $UserName: String!, $Password: String!, $DisplayName: String, $Pronouns: [PronounObject], $SocialLink: SocialLinkObject, $CaptivatedBy: String, $NeedToLive: String, $GratefulFor: String, $Avatar: S3Object, $Private: Int, $Role: Int, $SendEmailInvite: Boolean) {
  createUserAdmin(input: {Email: $Email, UserName: $UserName, Password: $Password, DisplayName: $DisplayName, Pronouns: $Pronouns, SocialLink: $SocialLink, CaptivatedBy: $CaptivatedBy, NeedToLive: $NeedToLive, GratefulFor: $GratefulFor, Avatar: $Avatar, Private: $Private, Role: $Role, SendEmailInvite: $SendEmailInvite}) {
    Status
    Message
  }
}`

export const ActivateUser = `mutation MyMutation($UserID: Int!) {
    confirmSignUpAdmin(UserID: $UserID) {
        Message
        Status
    }
}`

export const UpdateUserAdmin = `mutation MyMutation($Avatar: S3Object, $CaptivatedBy: String, $DisplayName: String, $GratefulFor: String, $NeedToLive: String, $Password: String, $Private: Int, $Pronouns: [PronounObject], $Role: Int, $SocialLink: SocialLinkObject, $UserID: Int!) {
    updateUserAdmin(input: {Avatar: $Avatar, CaptivatedBy: $CaptivatedBy, DisplayName: $DisplayName, GratefulFor: $GratefulFor, NeedToLive: $NeedToLive, Password: $Password, Private: $Private, Pronouns: $Pronouns, Role: $Role, SocialLink: $SocialLink, UserID: $UserID}) {
      Message
      Status
    }
}`

export const AddBundleCreditAdmin = `mutation MyMutation($Credit: Int!, $UserID: Int!) {
    addBundleCreditManualAdmin(Credit: $Credit, UserID: $UserID) {
        Message
        Status
    }
}`