import React from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ButtonTemplate } from '../button/button-template';

const useStyle = makeStyles(theme => ({
    title: {
        padding: '0 5px'
    },
    closeButton: {
        position: 'absolute',
        color: theme.palette.grey[500]
    },
}))

const MessageDialog = ({ message, setMessage }) => (
    <Dialog open={!!message} onClose={() => setMessage(false)}>
        <DialogTitle className={useStyle().title}>
            <Grid container direction='row' justify='flex-end'>
                <Grid item>
                    <IconButton onClick={() => setMessage(false)}>
                        <CloseIcon className={useStyle().closeButton} />
                    </IconButton>
                </Grid>
            </Grid>
        </DialogTitle>
        <DialogContent>
            <Box py={5}>{message}</Box>
        </DialogContent>
        <DialogActions>
            <Grid container direction='row' justify='flex-end'>
                <Box pb={5}>
                    <ButtonTemplate
                        variant='outlined'
                        color='primary'
                        size='small'
                        borderradius='5px'
                        onClick={() => setMessage(false)}
                    >Close</ButtonTemplate> 
                </Box>
            </Grid>
        </DialogActions>
    </Dialog>
)

export default MessageDialog;